<template>
  <b-modal
    id="modal_return_invoice_items"
    :title="$t('Devoluções')"
    no-close-on-backdrop
    hide-header-close
    size="xl"
    scrollable
    centered
    @hidden="hideModal"
  >
    <template #default>
      <FormulateForm
        ref="formReturnInvoiceItems"
        name="formReturnInvoiceItems"
      >
        <b-card-actions
          :title="`${$t('Dados da devolução')} - Pedido #${localForm.purchaseOrderId}`"
          :show-loading="fetching"
          no-actions
        >
          <b-row>
            <b-col md="2">
              <FormulateInput
                id="tax-classification-cfop-output"
                v-model="localForm.cfop"
                name="cfopOnput"
                type="vue-select"
                :label="$t('Cfop Devolução')"
                :placeholder="$t('Selecione')"
                :options="getComboCfopOutput"
                validation="required"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="address-active"
                v-model="localForm.isFullReturn"
                type="switch"
                :label="$t('Devolução total?')"
                @input="onChangeIsFullReturn"
              />
            </b-col>
            <b-col
              v-if="localForm.haveIcmsStOrIpi"
              md="2"
            >
              <FormulateInput
                id="include-taxes-other-costs"
                v-model="localForm.includeTaxesOtherCosts"
                type="switch"
                :label="$t('Discriminar os valores de ICMS ST, FCP ST e IPI como Outras Despesas')"
                :instructions="[
                  {
                    text: $t(
                      'Caso este campo esteja selecionado, os valores de ICMS ST, FCP ST e IPI serão destacados no campo Outras Despesas'
                    ),
                  },
                  {
                    text: $t(
                      'Será adicionado uma observação nos dados adicionais discriminando os valores do campo Outras Despesas'
                    ),
                  },
                  {
                    text: $t(
                      'Ex: Devolução parcial de sua NFe nº X de XX/XX/XXXX. O valor do campo Despesas Acessórias refere-se à:  ICMS ST: R$ XXX,XX; IPI: R$ XXX,XX;'
                    ),
                  },
                ]"
                @input="onIncludeTaxesOtherCosts"
              />
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          :title="$t('Produtos a serem retornados')"
          :show-loading="fetching"
          no-actions
        >
          <b-row class="mb-1">
            <b-col>
              <e-button
                :text="$t('Selecionar todos')"
                :disabled="localForm.isFullReturn || !hasItemsUnselected"
                variant="primary"
                @click="onSelectAll"
              />
            </b-col>
          </b-row>

          <b-table
            show-empty
            responsive
            striped
            class="bordered"
            :fields="fields"
            :items="localForm.items"
          >
            <template #cell(returnItem)="row">
              <div class="d-flex justify-content-center align-items-center">
                <FormulateInput
                  :id="`modal_return_item-return_check_${row.index}`"
                  v-model="row.item.willReturn"
                  type="e-checkbox"
                  :disabled="localForm.isFullReturn"
                />
              </div>
            </template>

            <template #cell(product)="row">
              <p class="m-0">
                {{ row.item.skuProduct.name }}
              </p>
            </template>

            <template #cell(returnedQuantity)="row">
              {{ row.item.returnedQuantity | number }}
            </template>

            <template #cell(enterQuantity)="row">
              {{ row.item.erpSku.quantity }}
            </template>

            <template #cell(unity)="row">
              {{ row.item.skuProduct.unitOfMeasurement }}
            </template>

            <template #cell(returnQuantity)="row">
              <FormulateInput
                :id="`modal_return_item-quantity_${row.index}`"
                v-model="row.item.returnQuantity"
                name="Qtd."
                type="text-number"
                :precision="2"
                :min="0"
                :validation="[
                  ['min', 0],
                  ['max', row.item.erpSku.quantity - row.item.returnedQuantity],
                ]"
                :validation-messages="{
                  max: `${$t('Deve ser menor que')} ${
                    row.item.erpSku.quantity - row.item.returnedQuantity
                  }`,
                }"
                :disabled="localForm.isFullReturn || !row.item.willReturn"
              />
            </template>
          </b-table>
        </b-card-actions>
      </FormulateForm>
    </template>

    <template #modal-footer>
      <b-row class="w-100 d-flex justify-content-between align-items-center">
        <!-- <b-col v-if="showProductReturn">
          <div class="d-flex align-items-center">
            <p class="h2 mb-0 text-dark">
              {{ $t('Total a devolver') }}
              <span class="h1 text-dark"> {{ localForm.value | currency }} </span>
            </p>
          </div>
        </b-col> -->

        <b-col class="d-flex justify-content-end">
          <e-button
            id="modal_return_invoice_items-btn_cancel"
            class="mr-1"
            variant="outline-primary"
            :text="$t('Cancelar')"
            :text-shortcuts="['ESC']"
            @click="hideModal"
          />

          <e-button
            id="modal_return_invoice_items-btn_confirm"
            variant="primary"
            :text="$t('Confirmar')"
            :busy="busy"
            :disabled="!canConfirmReturns"
            @click="onConfirm"
          />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BTable } from 'bootstrap-vue'
import { alerts } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters } from 'vuex'

const getInitialModalItem = () => ({
  willReturn: false,
  skuProduct: { name: '' },
  quantity: 0,
  returnedQuantity: 0,
  returnQuantity: 0,
})

const getInitialModalForm = () => ({
  purchaseOrderId: null,
  cfop: null,
  isFullReturn: false,
  items: [],
  includeTaxesOnOtherValues: false,
  haveIcmsStOrIpi: false,
})

export default {
  components: { BModal, BRow, BCol, EButton, BCardActions, BTable },

  mixins: [alerts],

  props: {},

  data() {
    return {
      localForm: getInitialModalForm(),
      busy: false,
      fetching: false,
    }
  },

  computed: {
    ...mapGetters('pages/invoice/taxClassifications', ['getComboCfopOutput']),

    canConfirmReturns() {
      return this.localForm.items.some(i => i.willReturn)
    },

    hasItemsUnselected() {
      return this.localForm.items.some(i => !i.willReturn)
    },

    fields() {
      return [
        {
          label: this.$t('Retornar'),
          key: 'returnItem',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Nome do Produto'),
          key: 'product',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        // {
        //   label: this.$t('Qtd. da NF'),
        //   key: 'quantity',
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        //   thStyle: { width: '100px' },
        // },
        {
          label: this.$t('Qtd. de Entrada'),
          key: 'enterQuantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Unidade'),
          key: 'unity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Qtd. Devolvida'),
          key: 'returnedQuantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Qtd. a devolver'),
          key: 'returnQuantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
      ]
    },
  },

  mounted() {
    this.fetchTaxClassificationCombos()
  },

  methods: {
    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderMaintain', {
      stFetchPurchaseOrderById: 'fetchPurchaseOrderById',
    }),
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),
    ...mapActions('pages/invoice/invoice/invoiceMaintain', ['purchaseOrderToReturn']),

    hideModal() {
      this.resetModal()
      this.$bvModal.hide('modal_return_invoice_items')
    },

    async show({ purchaseOrderId }) {
      this.$bvModal.show('modal_return_invoice_items')
      await new Promise(resolve => setTimeout(() => resolve(), 151))

      try {
        this.fetching = true
        this.busy = true

        const data = await this.stFetchPurchaseOrderById(purchaseOrderId)
        const itemsFormated = data.items.map(i => ({
          ...getInitialModalItem(),
          ...i,
          skuProduct: { ...i.erpSku?.sku },
          returnQuantity: i.erpSku.quantity,
        }))
        this.totalItems = itemsFormated.length
        const haveIcmsStOrIpi = itemsFormated.filter(f => f.taxes?.icmsStValue > 0 || f.taxes?.ipiValue > 0 || f.taxes?.fcpStValue > 0).length > 0
        this.localForm = {
          ...getInitialModalForm(),
          purchaseOrderId: data.id,
          items: itemsFormated,
          haveIcmsStOrIpi,
        }
      } catch (error) {
        this.hideModal()
        throw error
      } finally {
        this.fetching = false
        this.busy = false
      }
    },

    async onConfirm() {
      try {
        this.busy = true

        this.$refs.formReturnInvoiceItems.showErrors()
        if (!this.isValidInputs()) return

        const confirm = await this.confirm({
          title: this.$t('Confirma a devolução?'),
          text: this.$t(
            'Você será redirecionado para a tela de "Nova Nota Fiscal" para concluir a devolução.'
          ),
        })
        if (!confirm) return

        await this.purchaseOrderToReturn(this.localForm)
        this.$router.push({ name: 'invoice-create' })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.busy = false
      }
    },

    onSelectAll() {
      this.localForm.items = this.localForm.items.map(i => ({
        ...i,
        willReturn: true,
      }))
    },
    onChangeIsFullReturn(val) {
      if (val) {
        this.localForm.items = this.localForm.items.map(i => ({
          ...i,
          returnQuantity: i.erpSku.quantity,
          willReturn: true,
        }))
      }
    },
    onIncludeTaxesOtherCosts(val) {
      if (val) {
        this.localForm.includeTaxesOnOtherValues = val
      }
    },

    resetModal() {
      this.localForm = getInitialModalForm()
    },

    isValidInputs() {
      if (this.localForm.cfop === null) {
        this.showInvalidDataMessage({ message: this.$t('Em dados da devolução') })
        return false
      }

      if (
        this.localForm.items.some(
          i => i.returnQuantity > i.erpSku.quantity || i.returnQuantity < 0
        )
      ) {
        this.showInvalidDataMessage({
          message: this.$t('Em produtos retornados'),
        })
        return false
      }

      if (this.localForm.items.some(i => i.willReturn && i.returnQuantity === 0)) {
        this.showInvalidDataMessage({
          message: this.$t('Alguns items para retorno estão com a quantidade zerada'),
        })
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}

.input-size {
  font-size: 1.4rem;
}
</style>
