var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: "modal_return_invoice_items",
      title: _vm.$t("Devoluções"),
      "no-close-on-backdrop": "",
      "hide-header-close": "",
      size: "xl",
      scrollable: "",
      centered: "",
    },
    on: { hidden: _vm.hideModal },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "formReturnInvoiceItems",
                attrs: { name: "formReturnInvoiceItems" },
              },
              [
                _c(
                  "b-card-actions",
                  {
                    attrs: {
                      title:
                        _vm.$t("Dados da devolução") +
                        " - Pedido #" +
                        _vm.localForm.purchaseOrderId,
                      "show-loading": _vm.fetching,
                      "no-actions": "",
                    },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "tax-classification-cfop-output",
                                name: "cfopOnput",
                                type: "vue-select",
                                label: _vm.$t("Cfop Devolução"),
                                placeholder: _vm.$t("Selecione"),
                                options: _vm.getComboCfopOutput,
                                validation: "required",
                              },
                              model: {
                                value: _vm.localForm.cfop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localForm, "cfop", $$v)
                                },
                                expression: "localForm.cfop",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "address-active",
                                type: "switch",
                                label: _vm.$t("Devolução total?"),
                              },
                              on: { input: _vm.onChangeIsFullReturn },
                              model: {
                                value: _vm.localForm.isFullReturn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localForm, "isFullReturn", $$v)
                                },
                                expression: "localForm.isFullReturn",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.localForm.haveIcmsStOrIpi
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "include-taxes-other-costs",
                                    type: "switch",
                                    label: _vm.$t(
                                      "Discriminar os valores de ICMS ST, FCP ST e IPI como Outras Despesas"
                                    ),
                                    instructions: [
                                      {
                                        text: _vm.$t(
                                          "Caso este campo esteja selecionado, os valores de ICMS ST, FCP ST e IPI serão destacados no campo Outras Despesas"
                                        ),
                                      },
                                      {
                                        text: _vm.$t(
                                          "Será adicionado uma observação nos dados adicionais discriminando os valores do campo Outras Despesas"
                                        ),
                                      },
                                      {
                                        text: _vm.$t(
                                          "Ex: Devolução parcial de sua NFe nº X de XX/XX/XXXX. O valor do campo Despesas Acessórias refere-se à:  ICMS ST: R$ XXX,XX; IPI: R$ XXX,XX;"
                                        ),
                                      },
                                    ],
                                  },
                                  on: { input: _vm.onIncludeTaxesOtherCosts },
                                  model: {
                                    value: _vm.localForm.includeTaxesOtherCosts,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.localForm,
                                        "includeTaxesOtherCosts",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localForm.includeTaxesOtherCosts",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card-actions",
                  {
                    attrs: {
                      title: _vm.$t("Produtos a serem retornados"),
                      "show-loading": _vm.fetching,
                      "no-actions": "",
                    },
                  },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-1" },
                      [
                        _c(
                          "b-col",
                          [
                            _c("e-button", {
                              attrs: {
                                text: _vm.$t("Selecionar todos"),
                                disabled:
                                  _vm.localForm.isFullReturn ||
                                  !_vm.hasItemsUnselected,
                                variant: "primary",
                              },
                              on: { click: _vm.onSelectAll },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("b-table", {
                      staticClass: "bordered",
                      attrs: {
                        "show-empty": "",
                        responsive: "",
                        striped: "",
                        fields: _vm.fields,
                        items: _vm.localForm.items,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(returnItem)",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id:
                                        "modal_return_item-return_check_" +
                                        row.index,
                                      type: "e-checkbox",
                                      disabled: _vm.localForm.isFullReturn,
                                    },
                                    model: {
                                      value: row.item.willReturn,
                                      callback: function ($$v) {
                                        _vm.$set(row.item, "willReturn", $$v)
                                      },
                                      expression: "row.item.willReturn",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(product)",
                          fn: function (row) {
                            return [
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " + _vm._s(row.item.skuProduct.name) + " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(returnedQuantity)",
                          fn: function (row) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("number")(row.item.returnedQuantity)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(enterQuantity)",
                          fn: function (row) {
                            return [
                              _vm._v(
                                " " + _vm._s(row.item.erpSku.quantity) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(unity)",
                          fn: function (row) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.item.skuProduct.unitOfMeasurement
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(returnQuantity)",
                          fn: function (row) {
                            return [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "modal_return_item-quantity_" + row.index,
                                  name: "Qtd.",
                                  type: "text-number",
                                  precision: 2,
                                  min: 0,
                                  validation: [
                                    ["min", 0],
                                    [
                                      "max",
                                      row.item.erpSku.quantity -
                                        row.item.returnedQuantity,
                                    ],
                                  ],
                                  "validation-messages": {
                                    max:
                                      _vm.$t("Deve ser menor que") +
                                      " " +
                                      (row.item.erpSku.quantity -
                                        row.item.returnedQuantity),
                                  },
                                  disabled:
                                    _vm.localForm.isFullReturn ||
                                    !row.item.willReturn,
                                },
                                model: {
                                  value: row.item.returnQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(row.item, "returnQuantity", $$v)
                                  },
                                  expression: "row.item.returnQuantity",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "b-row",
              {
                staticClass:
                  "w-100 d-flex justify-content-between align-items-center",
              },
              [
                _c(
                  "b-col",
                  { staticClass: "d-flex justify-content-end" },
                  [
                    _c("e-button", {
                      staticClass: "mr-1",
                      attrs: {
                        id: "modal_return_invoice_items-btn_cancel",
                        variant: "outline-primary",
                        text: _vm.$t("Cancelar"),
                        "text-shortcuts": ["ESC"],
                      },
                      on: { click: _vm.hideModal },
                    }),
                    _c("e-button", {
                      attrs: {
                        id: "modal_return_invoice_items-btn_confirm",
                        variant: "primary",
                        text: _vm.$t("Confirmar"),
                        busy: _vm.busy,
                        disabled: !_vm.canConfirmReturns,
                      },
                      on: { click: _vm.onConfirm },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }